.stake-heading {
	padding:120px 0 150px 0;
	background:#272f7d;
	color:#fff;
	margin-top:-65px;

	h2 {
		font-weight: 700;
		margin:25px 0 20px 0;
		font-size: 3em;
		line-height: 1;
	}
	p {
		font-size:20px;
		line-height: 1.5;
		font-weight: 500;
		font-family: 'Lora', serif;
		opacity: 0.7;
	}

	.avatar {
		width:100px;
		height:100px;
		

		&.celo, &.polkadot, &.polymath, &.tron, &.ada, &.tezos, &.cosmos, &.keep, &.synthetix {
			background:#fff;
			img {
				width:80px;
				height:80px;
			}
			border:3px solid #12184c;
		}
		&.tron {
			img {
				width:60px;
				height:60px;
				position: relative;
				top:5px;
			}
		}
		&.polymath {
			img {
				width:70px;
				height:70px;
			}
		}
		&.ada {
			img {
				width:60px;
				height:60px;
			}
		}
		&.tezos {
			img {
				width:60px;
				height:60px;
			}
		}
		&.keep {
			img {
				width:50px;
				height:50px;
			}
		}
		&.synthetix {
			img {
				width:39px;
				height:50px;
			}
		}
	}
}

.stake {
	.kickstart-form {
		background:#fff;
		padding:20px;
		border-radius: 6px;
		margin-top:-70px;
		.heading {
			h3 {
				font-size:33px;
				margin:0 0 5px 0;
				font-weight: 700;
			}
			p {
				color:#676767;
				font-size:18px;
				margin:0 0 0 0;
			}
		}
		.cta {
			margin:15px 0 0 0;
			color:#333;
			h4 {
				font-size:20px;
				margin:0 0 10px 0;
				font-weight: 600;
				
			}
			ul {
				padding:0 0 0 15px;
				li {
					font-size:15px;
					margin: 0 0 5px 0;
				}
			}
		}
		
	}
	.dyi {
		.heading {
			h4 {
				font-size:20px;
				margin:0 0 5px 0;
			}
			p {
				color:#676767;
				font-size:15px;
				margin:0 0 15px 0;
			}
		}
		.steps {
			padding:0 0px 0 15px;
			
			.step {
				font-size:14px;
				margin-bottom: 15px;
				.substeps {
					padding:0 0 0 15px;
					margin-top:7px;
					color:#676767;
					.substep {
						font-size:12px;
						margin-bottom: 7px;
						line-height: 1.5;
						code {
							font-size:inherit;
						}
					}
				}
			}
		}
	}
}