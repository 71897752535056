.team {
	margin-bottom: 100px;
	.heading {
		margin-top:80px;
		h3 {
			font-weight: 700;
			font-size:32px;
			line-height: 1.3;
			margin:0 0 30px 0;
		}
		p {
			
			font-family: 'Lora', serif;
			font-size:19px;
			color:#676767;
		}
	}
	img {
		border-radius: 8px;
		margin-bottom: 15px;
	}
	.name {
		font-size: 25px;
	}
	.position {
		color:#666;
		font-family: 'Lora', serif;
		font-weight: 600;
		font-size:19px;
	}
}

@media (max-width: 767px) { 
	.platforms {
		.flex-nowrap {
			flex-wrap: nowrap !important;
		}
	}
}