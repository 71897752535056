.footer {
	background:#272f7d;
	color:#fff;
	padding-top:40px;
	padding-bottom: 40px;

	.header {
		font-size:18px;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		opacity: 0.7;
		margin:0 0 30px 0;
	}
	
	.logo-image {
		height:38px;
		margin-right: 15px;
	}

	.divider {
		background:#fff;
		opacity: 0.2;
		margin:30px 0 45px 0;
	}

	.legal {
		font-size:13px;
		a {
			color:#fff;
			//font-weight:600;
			opacity: 0.7;
		}
	}

	ul {
		padding:0;
		margin:0;
		li {
			list-style: none;
			margin:0 0 7px 0;
			a {
				color:#fff;
				
			}
		}
	}

	.social-media-holder {
		a {
			color:#fff;
			text-decoration: none;
			display:inline-block;

			img {
				width:22px;
				opacity: 0.8;
			}
		}
	}

	.grow { transition: all .2s ease-in-out; }
	.grow:hover { transform: scale(1.2); }

}

@media (max-width: 767px) { 
	.footer {
		.divider {
			margin:55px 0 45px 0;
		}
	}
}