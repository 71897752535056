.swap-widget-alt {
	height:100%;
	margin-top: -64px;
	
	.widget {
		background:#2a3eb1;
		border-radius: 9px;
		padding:15px;
		.from, .to {
			.label {
				margin: 0 0 11px 0;
				line-height: 1;
				display: block;
				color:#fff;
				font-weight: 600;
				text-transform: uppercase;
				font-size:11px;
			}
			.select-token {
				border:1px solid rgba(255,255,255,0.5);
				min-width:110px;
				min-height:40px;
				margin-right:15px;
				font-weight: 600;
				color:#fff;
				border-radius: 9px;
				.token-icon-avatar {
					margin-right:5px;
					width:20px;
					height:20px;
					background:#fff;
					img {
						height:16px;
						width:16px;
					}
				}
			}
		}
		.to {
			margin-top: 15px;
			border-top:1px solid rgba(255,255,255,0.2);
			padding-top:15px;
			.loading-block {
				position: absolute;
				width:100%;
				height:100%;
				background:rgba(255,255,255,0.4);
				left:0;
				top:0;
				z-index: 1;
				border-bottom-left-radius: 9px;
				border-bottom-right-radius: 9px;
			}
			.loading-wrapper {
				position: relative;
			}
		}
		.amount-input {
			margin:0 0 0 0;
			background:transparent;
			min-height:40px;
			border:none;
			border-radius: 0px;
			//padding:5px 15px 5px 0;
			position: relative;
			top:0px;
			//border-bottom:1px solid rgba(255,255,255,0.5);
			font-size:2em;
			font-weight:500;
			//text-align: center;
			outline: none;
			letter-spacing: -0.5px;
			line-height: 1;
			color:#fff;
			&::placeholder {
				color: #fff;
				opacity: 0.3; /* Firefox */
			}
			&.readonly {
				opacity: 0.7;
			}
		}
	}
}


@media (max-width: 767px) { 
	.swap-widget-alt {
		margin-top: 15px;
		height:auto;
	}
}

.swap-header {
	.address-wrapper {
		background:#eee;
		padding:5px 7px 5px 7px;
		border-radius: 50px;
		border:2px solid #ddd;
		span {
			font-size:13px;
			margin-left: 5px;
			font-weight: 600;
			color:#444
		}
	}
}

.swap-modal {
	.modal-token-icon-avatar {
		height:25px;
		width:25px;
		font-size: 13px;
		img {
			height:25px;
			width:25px;
		}
	}
}

.transfer-module {
	padding:0;
	margin-top:15px;
	
	.summary {
		padding:15px 15px 0 15px;
		background: #f3f3f3;
		border-radius: 9px;
		border:1px solid #e2e2e2;
	}
	h5 {
		font-size:16px;
	}
	.key {
		opacity: 0.5;
		font-size:14px;
	}
	.value {
		font-size:14px;
		font-weight: 600;
		-ms-word-break: break-all;
		word-break: break-all;

	/* Non standard for webkit */
		word-break: break-word;

		-webkit-hyphens: auto;
		-moz-hyphens: auto;
			-ms-hyphens: auto;
				hyphens: auto;
	}
}