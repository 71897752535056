.assets {
	//background: url('./../assets/images/tokens-background.jpg') no-repeat center center; 
	background-color: #f9f9f9;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	margin-top:120px;
	padding-bottom: 120px;

	.masonry-grid {

		width:100%;
	
		.masonry-grid-column {
			.masonry-item {  
				//margin-right: 30px;
				margin-bottom: -15px;
			}
	
		}
	
	}
	.heading {
		h3 {
			font-weight: 700;
			font-size:32px;
			line-height: 1;
			margin:150px 0 0 0;
		}
		p {
			margin:15px 0 80px 0;
			font-family: 'Lora', serif;
			font-size:19px;
			color:#676767;
		}
	}
	.list {
		.asset {
			margin-bottom: 30px;
			background:#f3f3f3;
			h4 {
				font-weight: 600;
				font-size:21px;
				margin:0 0 7px 0;
			}
			p {
				color:#999;
				margin:0 0 15px 0;
				font-family: 'Lora', serif;
				//font-size:13px;
			}
			.stake-button {
				background:rgba(63,81,181,0.1);
				box-shadow: none;
				color:rgb(63,81,181);
			}
			.avatar {
				width:60px;
				height:60px;
				&.celo, &.polkadot, &.polymath, &.tron, &.ada, &.tezos, &.cosmos, &.keep, &.synthetix {
					background:#fff;
					img {
						width:40px;
						height:40px;
					}
					border:2px solid #eee;
				}
				&.polkadot {
					img {
						width:45px;
						height:45px;
					}
				}
				&.tron {
					img {
						width:30px;
						height:30px;
					}
				}
				&.polymath {
					img {
						width:40px;
						height:40px;
					}
				}
				&.ada {
					img {
						width:35px;
						height:35px;
					}
				}
				&.tezos {
					img {
						width:30px;
						height:30px;
					}
				}
				&.keep {
					img {
						width:28px;
						height:28px;
					}
				}
				&.synthetix {
					img {
						width:24px;
						height:35px;
					}
				}
			}
		}
	}
}

@media (max-width: 767px) { 
	.assets {
		padding-bottom: 50px;
		.heading {
			br{content:' ';}
			br:after{content:' ';}
			p {
				margin-bottom:50px;
			}
		}
	}
}