.about {
	margin-top: 0;
	//margin-bottom: 110px;
	.sep {
		background-color: #4f58d3;
		width:40px;
		margin:0 0 15px 0;
		height:3px;
	}
	.overlapping-header {
		//margin-top:80px;
		font-weight: 700;
		font-size:35px;
		line-height: 1.5;
		//padding-right: 80px;
		margin-bottom:50px;
	}
	.breakaway {
		//background:#f1f1f1;
		//padding:85px;
		//padding-top:85px;
		//padding-bottom: 85px;
		p {
			font-family: 'Lora', serif;
			font-size:21px;
			color:#282828;
			margin-bottom: 15px;
		}
		z-index: 1;
	}
}

@media (max-width: 767px) { 
	.about {
		//margin-top: 0px;
	}
}