.platforms {
	.heading {
		margin-top:80px;
		h3 {
			font-weight: 700;
			font-size:32px;
			line-height: 1.3;
			margin:0 0 0 0;
		}
		p {
			margin:15px 0 0 0;
			font-family: 'Lora', serif;
			font-size:19px;
			color:#676767;
		}
	}

	.flex-nowrap {
		flex-wrap: wrap !important;
	}

	.list {
		margin:65px 0 80px 0;
		.platform {
			margin-bottom: 30px;
			h4 {
				font-weight: 600;
				font-size:18px;
				margin:10px 0 0 0;
			}
			.avatar {
				width:80px;
				height:80px;
				&.uniswap {
					background:#FF00F3;
					border:2px solid #FF00F3;
					img {
						width:50px;
						height:50px;
					}
				}
				&.kyber {
					background:#fff;
					border:2px solid #37CCA0;
					img {
						width:50px;
						height:50px;
					}
				}
				&.fulcrum {
					background:#fff;
					border:2px solid #1889d3;
					img {
						width:60px;
						height:60px;
					}
				}
				&.chai {
					background:#fff;
					border:2px solid #dd3745;
					img {
						width:60px;
						height:60px;
						position: relative;
						top:-6px;
						left:1px;
					}
				}
				&.set {
					background:#fff;
					border:2px solid #5f39ff;
					img {
						width:60px;
						height:60px;
			
					}
				}
				&.idle {
					border:2px solid #1971ec
				}
				&.synthetix {
					border:2px solid #333;
					background:#fff;
					img {
						width:29px;
						height:40px;
					}
				}
			}
		}
	}
}

@media (max-width: 767px) { 
	.platforms {
		.flex-nowrap {
			flex-wrap: nowrap !important;
		}
	}
}