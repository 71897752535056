.featurette {
	margin-top:100px;
	.caption {
		color:#b2b2b2;
		font-weight: 600;
		border-bottom:3px solid #4f58d3;
		display:block;
		font-size:18px;
		padding:0 0 10px 0;
	}	
	h3 {
		font-weight: 700;
		font-size:35px;
		margin:15px 0 5px 0;
		line-height: 1.3;
	}
	p {
		font-family: 'Lora', serif;
		color:#676767;
		font-size:21px;
	}
	
	&.horizontal-wrapper {
		overflow-x: auto;
    	-webkit-overflow-scrolling: touch;
	}
}

@media (max-width: 767px) { 
	.featurette {
		h3 {
			font-size:30px;
		}
		p {
			font-size:18px;
		}
	}
}