.hero {
	margin-top:-65px;
	padding-top:120px;
	background: url('./../assets/images/hero-background.jpg') no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding-bottom: 120px;

	img {
		max-height:600px;
		-webkit-transform: scaleX(-1);
  		transform: scaleX(-1);
	}

	h2 {
		font-weight: 700;
		font-size:43px;
		line-height: 1.3;
		margin:0px 0 50px 0;
	}

	p {
		color:#4f4f4f;
		font-size:22px;
		line-height: 1.3;
		font-weight: 500;
		font-family: 'Lora', serif;
		margin:0 0 0 0;
	}
	
}

@media (max-width: 767px) { 
	.hero {
		img {
			max-width: 100%;
			height: auto;
		}
		p {
			margin-bottom: 50px;
		}
	}
}